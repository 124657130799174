import React from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import { alpha } from '@mui/material/styles';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { AboutSection, COMPANY_EMAIL } from '@/utils/constants';
import { colorGold } from '@/utils/colors';

// utils
import { scrollToTargetAdjusted } from '@/utils/scroll-to';

/**
 * section home
 */
const SectionHome = () => {
  const sectionBackroundColor = alpha(grey[900], 0.8);

  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "banner-cover.jpg" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    `,
  );
  const image = getImage(placeholderImage);

  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage
      Tag="section"
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
    >
      <Grid
        id="home"
        container
        justifyContent="flex-start"
        sx={{
          width: '100%',
          minHeight: {
            md: '100vh',
            xs: '90vh',
          },
          backgroundColor: sectionBackroundColor,
        }}
      >
        {/** vertical link */}
        <Grid
          item
          container
          alignItems="flex-end"
          justifyContent="center"
          xs={1}
          sx={{ display: { xs: 'none', md: 'flex' } }}
        >
          <Box
            sx={{
              writingMode: 'tb-rl',
              transform: 'rotate(-180deg)',
              mb: '48px',
              ml: '8px',
            }}
          >
            <Link
              href={`mailto:${COMPANY_EMAIL}`}
              variant="body2"
              sx={{ color: colorGold, letterSpacing: '2px' }}
            >
              {`m: ${COMPANY_EMAIL}`}
            </Link>
          </Box>
        </Grid>

        {/** main typing */}
        <Grid
          item
          xs
          sx={{
            p: '48px',
            mt: {
              md: 25,
              xs: 24,
            },
          }}
        >
          <Typography
            variant="h1"
            sx={{ color: colorGold, letterSpacing: '3px' }}
          >
            World B2B
          </Typography>
          <Typography variant="h1" sx={{ color: colorGold }}>
            car stock consulting
          </Typography>
          <Typography variant="h1" sx={{ color: colorGold }}>
            and more
          </Typography>

          <Button
            variant="outlined"
            size="large"
            color="secondary"
            sx={{ mt: '48px', color: colorGold }}
            onClick={() => scrollToTargetAdjusted(AboutSection.id)}
          >
            see what we do
          </Button>
        </Grid>
      </Grid>
    </BackgroundImage>
  );
};

export default SectionHome;
