import React from 'react';

import { Box, Grid, Theme, useMediaQuery } from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

import { grey } from '@mui/material/colors';
import { ContactSection } from '@/utils/constants';
import ContactForm from '../ContactForm/ContactForm';

/**
 * section contact
 */
// eslint-disable-next-line arrow-body-style
const SectionContact = () => {
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Grid
        container
        id={ContactSection.id}
        sx={{ width: '100%' }}
        direction="row"
      >
        {isLargeScreen && (
          <Grid
            item
            container
            alignItems="center"
            md={6}
            sx={{
              backgroundColor: grey[900],
            }}
          >
            <StaticImage
              src="../../assets/images/contact-us.jpg"
              alt="contact us"
              placeholder="blurred"
              layout="fixed"
              height={1080}
              width={1400}
            />
          </Grid>
        )}

        <Grid
          item
          container
          alignItems="center"
          md={6}
          sx={{ backgroundColor: grey[50], pl: { md: 4 }, pt: 8, pb: 8 }}
        >
          <ContactForm />
        </Grid>
      </Grid>
    </Box>
  );
};

export default SectionContact;
