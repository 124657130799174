/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';

export const UI = types
  .model({
    isDrawerOpen: types.boolean,
  })
  .actions((self) => ({
    setMenuOpen(val: boolean) {
      self.isDrawerOpen = val;
    },
  }));
