import React from 'react';

import DefaultLayout from '@/components/Layout/DefaultLayout';
import SectionAbout from '@/components/SectionAbout/SectionAbout';
import SectionContact from '@/components/SectionContact/SectionContact';
import SectionHome from '@/components/SectionHome/SectionHome';

// eslint-disable-next-line arrow-body-style
const Home = () => {
  return (
    <DefaultLayout>
      <SectionHome />
      <SectionAbout />
      <SectionContact />
    </DefaultLayout>
  );
};

export default Home;
