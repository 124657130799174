import React from 'react';

import { Box, Divider, Grid, Link, Typography } from '@mui/material';
import IconTelephone from '@mui/icons-material/LocalPhone';
import IconEmail from '@mui/icons-material/Email';
import IconLocation from '@mui/icons-material/LocationOn';

import { grey } from '@mui/material/colors';

import {
  COMPANY_ADDRESS,
  COMPANY_EMAIL,
  COMPANY_TELEPHONE,
} from '@/utils/constants';

// assets
import IcarusLogoTextBlackfrom from '@/assets/svg/icarus-logo-text-black.svg';

/**
 * footer
 */
const Footer = () => {
  const footerBackgroundColor = grey[300];

  return (
    <>
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        spacing={4}
        sx={{ p: '48px', backgroundColor: footerBackgroundColor }}
      >
        <Grid item md={6} container>
          <IcarusLogoTextBlackfrom height={24} />

          <Divider light sx={{ width: '100%', p: 2 }} />
          <Typography
            variant="caption"
            sx={{
              mt: 2,
              letterSpacing: '2px',
              display: { xs: 'none', md: 'block' },
            }}
          >{`${new Date().getFullYear()} @icarus`}</Typography>
        </Grid>
        {/** company info */}
        <Grid item container md={6} direction="column">
          {/** email */}
          <Grid item container alignItems="center">
            <IconEmail fontSize="small" />
            <Link
              href={`mailto:${COMPANY_EMAIL}`}
              color="inherit"
              variant="caption"
              sx={{ pl: '16px' }}
            >
              {COMPANY_EMAIL}
            </Link>
          </Grid>

          {/** telephone */}
          <Grid item container alignItems="center" sx={{ pt: '16px' }}>
            <IconTelephone fontSize="small" />

            <Typography variant="caption" sx={{ pl: '16px' }}>
              <Link
                href={`tel:${COMPANY_TELEPHONE}`}
                variant="inherit"
                color="inherit"
              >
                {COMPANY_TELEPHONE}
              </Link>
            </Typography>
          </Grid>

          {/** adress */}
          <Grid item container alignItems="center" sx={{ pt: '16px' }}>
            <IconLocation fontSize="small" />
            <Typography variant="caption" sx={{ pl: '16px' }}>
              {COMPANY_ADDRESS}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          p: 2,
          backgroundColor: '#212121',
          textAlign: 'center',
          display: { xs: 'block', md: 'none' },
        }}
      >
        <Typography
          color="white"
          variant="caption"
          sx={{ letterSpacing: '2px' }}
        >{`${new Date().getFullYear()} @icarus`}</Typography>
      </Box>
    </>
  );
};

export default Footer;
