import * as React from 'react';

// data
import { Provider, rootStore } from '@/data/Root';
import { CssBaseline, ThemeProvider } from '@mui/material';
import theme from '@/theme';

// components
import CustomAppBar from '../CustomAppBar/CustomAppBar';
import NavigationDrawer from '../NavigationDrawer/NavigationDrawer';

// style
import './default-layout.css';
import Footer from '../Footer/Footer';

// eslint-disable-next-line arrow-body-style
const DefaultLayout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Provider value={rootStore}>
        <CustomAppBar />
        <NavigationDrawer />

        <main>{children}</main>
        <footer>
          <Footer />
        </footer>
      </Provider>
    </ThemeProvider>
  );
};

export default DefaultLayout;
