import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { sections, HomeSection } from '@/utils/constants';
import { scrollToView } from '@/utils/scroll-to';
import IcarusLogoWhite from '@/assets/svg/icarus-logo-white.svg';

// data
import { useMst } from '@/data/Root';
import { Grid } from '@mui/material';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { grey } from '@mui/material/colors';

const CustomAppBar = () => {
  // data
  const { ui } = useMst();

  const [scrolled, setScrolled] = useState(false);

  useScrollPosition(({ currPos }) => {
    setScrolled(currPos.y < -400);
  });

  return (
    <AppBar
      position="fixed"
      sx={{
        minHeight: '88px',
        height: '88px',
        paddingRight: 0,

        ...(scrolled
          ? {
              backgroundColor: grey[900],
            }
          : {
              backgroundColor: 'transparent',
              boxShadow: 'none',
            }),
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        sx={{ minHeight: 'inherit' }}
      >
        <Grid item>
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              {/** small screen toolbar */}
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                sx={{ display: { md: 'none' } }}
              >
                <Grid
                  item
                  sx={{
                    pl: '8px',
                  }}
                >
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => scrollToView(HomeSection.id)}
                    color="inherit"
                  >
                    <IcarusLogoWhite
                      style={{ height: '42px', width: '42px' }}
                    />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={() => ui.setMenuOpen(true)}
                    color="inherit"
                  >
                    <MenuIcon />
                  </IconButton>
                </Grid>
              </Grid>

              {/** large screen toolbar */}
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'start',

                  pl: '8px',
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => scrollToView(HomeSection.id)}
                  color="inherit"
                >
                  <IcarusLogoWhite style={{ height: '42px', width: '42px' }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'end',
                }}
              >
                {sections.map((section) => (
                  <Button
                    variant="text"
                    component="h5"
                    key={section.id}
                    onClick={() => scrollToView(`${section.id}`)}
                    size="large"
                    sx={{
                      my: 2,
                      color: 'white',
                      display: 'block',
                      fontFamily: 'Oswald',
                    }}
                  >
                    {section.title}
                  </Button>
                ))}
              </Box>
            </Toolbar>
          </Container>
        </Grid>
      </Grid>
    </AppBar>
  );
};

export default CustomAppBar;
