/*
 * global constants
 */

import { SinglePageSection } from '@/types';

export const HomeSection: SinglePageSection = {
  id: 'home',
  title: 'Home',
};

export const AboutSection: SinglePageSection = {
  id: 'about',
  title: 'About',
};

export const ContactSection: SinglePageSection = {
  id: 'contact',
  title: 'Contact',
};

export const sections: SinglePageSection[] = [
  HomeSection,
  AboutSection,
  ContactSection,
];

export const COMPANY_EMAIL = 'icarus.automotive@gmail.com';
export const COMPANY_WEB = 'icarus-automotive.com';
export const COMPANY_TELEPHONE = '+385 98 757 623';
export const COMPANY_ADDRESS = 'Trsine 15, 52100 Pula, Croatia';

export const aboutText = `Our goal is to provide information regarding car stocks all around the world in order to allow our customers to obtain clear and precise information. Our business model gives the opportunity to our customers to review the information quickly in order to select the best deal at the moment. \nThe main focus will always be on our buyers, that is why we offer stock research for customers who are interested in a particular brand or model not present on the customer market jet.`;
