import scrollTo from 'gatsby-plugin-smoothscroll';

/**
 * scroll to position with offset
 */
export const scrollToTargetAdjusted = (viewId: string, offset = 0) => {
  const element = document.getElementById(viewId);

  if (!element) return;
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth',
  });
};

export const scrollToView = (viewId: string) => {
  scrollTo(`#${viewId}`);
};

export const scrollToTop = () => {
  scrollTo('start');
};
