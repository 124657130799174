/**
 * navigation drawer
 */
import React from 'react';

// data
import { useMst } from '@/data/Root';

// components
import {
  Grid,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  SwipeableDrawer,
} from '@mui/material';

// icons
import CloseIcon from '@mui/icons-material/Close';

// libs
import { observer } from 'mobx-react-lite';

// constants
import { COMPANY_EMAIL, COMPANY_TELEPHONE, sections } from '@/utils/constants';
import { scrollToTargetAdjusted } from '@/utils/scroll-to';

import IcarusLogoTextBlack from '@/assets/svg/icarus-logo-text-black.svg';

const NavigationDrawer = observer(() => {
  // data
  const { ui } = useMst();

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      ui.setMenuOpen(open);
    };

  const onNavItemClick = (sectionId: string) => {
    ui.setMenuOpen(false);
    scrollToTargetAdjusted(sectionId);
  };

  const header = () => (
    <Grid
      item
      container
      justifyContent="space-between"
      alignItems="center"
      sx={{ minHeight: '88px' }}
    >
      <Grid item xs={2}>
        <IcarusLogoTextBlack />
      </Grid>
      <Grid item>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => ui.setMenuOpen(false)}
          color="inherit"
        >
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );

  const list = () => (
    <Grid item xs={8} sx={{ paddingTop: '24px' }}>
      <List>
        {sections.map((section) => (
          <ListItemButton
            key={section.id}
            onClick={() => onNavItemClick(section.id)}
            sx={{ paddingLeft: 0 }}
          >
            <ListItemText
              primary={section.title}
              primaryTypographyProps={{ variant: 'h5' }}
            />
          </ListItemButton>
        ))}
      </List>
    </Grid>
  );

  const footer = () => (
    <Grid
      item
      container
      direction="column"
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={2}
    >
      <Grid item>
        <Link
          href={`tel:${COMPANY_TELEPHONE}`}
          color="inherit"
          sx={{ fontSize: '0.8rem' }}
        >
          {`t: ${COMPANY_TELEPHONE}`}
        </Link>
      </Grid>
      <Grid item>
        <Link
          href={`mailto:${COMPANY_EMAIL}`}
          color="inherit"
          sx={{ fontSize: '0.8rem' }}
        >
          {`m: ${COMPANY_EMAIL}`}
        </Link>
      </Grid>
    </Grid>
  );

  return (
    <SwipeableDrawer
      anchor="left"
      open={ui.isDrawerOpen}
      onClose={toggleDrawer(false)}
      onOpen={toggleDrawer(true)}
      PaperProps={{
        style: {
          width: '100%',
        },
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="space-between"
        sx={{
          width: '100%',
          height: '100%',
          paddingLeft: '24px',
          paddingBottom: '24px',
          paddingRight: {
            xs: '16px',
            md: '32px',
          },
        }}
      >
        {/** header */}
        {header()}
        {list()}
        {footer()}
      </Grid>
    </SwipeableDrawer>
  );
});

export default NavigationDrawer;
