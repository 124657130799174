import React from 'react';

import { NewLineTextProps } from './interfaces/NewLineTextProps';

/**
 * section title
 */
const NewLineText = ({ text }: NewLineTextProps): JSX.Element => {
  const newText = text
    .split('\n')
    .map((str) => <p key={`line-text${str}`}>{str}</p>);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{newText}</>;
};

export default NewLineText;
