import React, { useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  message: yup.string().required('A message is required'),
});

function encode(data) {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

/**
 * contact form with formk and yup
 */
const ContactForm = () => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogErrorOpen, setDialogErrorOpen] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      email: '',
      message: '',
    },

    validationSchema,
    onSubmit: (values) => {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'contact',
          subject: 'request from icarus site',
          ...values,
        }),
      })
        .then((response) => {
          if (response.ok) {
            setDialogOpen(true);
            formik.resetForm();
          } else {
            setDialogErrorOpen(true);
          }
        })
        .catch(() => {
          setDialogErrorOpen(true);
        });
    },
  });

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogErrorClose = () => {
    setDialogErrorOpen(false);
  };

  return (
    <>
      <Box sx={{ p: '32px' }}>
        <Typography variant="h4">Get in touch</Typography>
        <Typography variant="body1" sx={{ mt: '24px' }}>
          For more information or collaboration proposals please feel free to
          contact us. We will get to you as soon as possible.
        </Typography>

        <form
          onSubmit={formik.handleSubmit}
          name="contact"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <TextField
            id="email"
            fullWidth
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            sx={{ mt: '48px' }}
          />
          <TextField
            id="body"
            fullWidth
            name="message"
            label="Message"
            type="text"
            value={formik.values.message}
            multiline
            minRows={6}
            maxRows={6}
            onChange={formik.handleChange}
            error={formik.touched.message && Boolean(formik.errors.message)}
            helperText={formik.touched.message && formik.errors.message}
            sx={{ mt: '24px' }}
          />
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            sx={{ mt: '24px' }}
          >
            Send message
          </Button>
        </form>
      </Box>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogOpen}
        onClose={handleDialogClose}
      >
        <DialogTitle>Thank you for reaching out</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We will respond within shortest period of the time
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Ok</Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={dialogErrorOpen}
        onClose={handleDialogErrorClose}
      >
        <DialogTitle>Error</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Message not sent. Please try again.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogErrorClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContactForm;
