import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

// A custom theme for this app
const theme = createTheme({
  // colors
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#ffffff',
    },
    error: {
      main: red.A400,
    },
  },

  // typography
  typography: {
    h4: {
      fontFamily: 'Oswald',
    },
    h5: {
      fontFamily: 'Oswald',
    },
    fontFamily: ['Montserrat', 'Oswald'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Montserrat';
          font-style: normal;
          font-display: swap;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

theme.typography.h1 = {
  fontFamily: 'Oswald',

  fontSize: '2.2rem',
  '@media (min-width:600px)': {
    fontSize: '4rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '6rem',
  },
};

theme.typography.h2 = {
  fontFamily: 'Oswald',

  fontSize: '2.75rem',

  [theme.breakpoints.up('sm')]: {
    fontSize: '3.50rem',
  },

  [theme.breakpoints.up('md')]: {
    fontSize: '3.75rem',
  },
};

export default theme;
