import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import {
  Box,
  Grid,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { AboutSection, aboutText } from '@/utils/constants';
import NewLineText from '../NewLineText/NewLineText';

/**
 * section about
 */
const SectionAbout = () => {
  const sectionBackroundColor = grey[300];

  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );

  return (
    <Box id={AboutSection.id}>
      {!isLargeScreen && (
        <Stack sx={{ backgroundColor: sectionBackroundColor }}>
          <Typography
            align="left"
            variant="h2"
            sx={{
              letterSpacing: '2px',
              pl: 4,
              pr: 4,
              pb: 4,
              pt: 12,
            }}
          >
            About us
          </Typography>
          <Typography
            variant="body1"
            sx={{
              pt: 2,
              pl: 4,
              pr: 4,
              mb: 8,
              whiteSpace: 'pre-wrap',
            }}
          >
            {aboutText}
          </Typography>
        </Stack>
      )}

      {/* bigger screens */}
      {isLargeScreen && (
        <Grid container sx={{ width: '100%' }} direction="row">
          <Grid item md={6} sx={{ p: 16 }}>
            <Typography variant="h2" sx={{ mb: 4 }}>
              About us
            </Typography>
            <Typography variant="body1" component="span">
              <NewLineText text={aboutText} />
            </Typography>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            md={6}
            sx={{
              backgroundColor: grey[900],
            }}
          >
            <StaticImage
              src="../../assets/images/about-section.jpg"
              alt="contact us"
              placeholder="blurred"
              layout="fixed"
              height={900}
              width={1400}
            />
          </Grid>
        </Grid>
      )}
    </Box>
    // <BackgroundImage
    //   Tag="section"
    //   // Spread bgImage into BackgroundImage:
    //   {...bgImage}
    //   preserveStackingContext
    // >
    //   <Box id={AboutSection.id} sx={{ backgroundColor: sectionBackroundColor }}>
    //     {/* smaller screens */}
    //     <Stack>
    //       <Typography
    //         align="left"
    //         variant="h2"
    //         sx={{
    //           letterSpacing: '2px',
    //           pl: 4,
    //           pr: 4,
    //           pb: 4,
    //           pt: 12,
    //           display: {
    //             xs: 'block',
    //             sm: 'none',
    //           },
    //         }}
    //       >
    //         About us
    //       </Typography>
    //       <Typography
    //         variant="body1"
    //         sx={{
    //           pt: 2,
    //           pl: 4,
    //           pr: 4,
    //           mb: 8,
    //           whiteSpace: 'pre-wrap',
    //           display: {
    //             xs: 'block',
    //             sm: 'none',
    //           },
    //         }}
    //       >
    //         {aboutText}
    //       </Typography>
    //     </Stack>

    //     {/* bigger screens */}
    //     {isLargeScreen && (
    //       <Grid
    //         container
    //         direction="row"
    //         alignItems="center"
    //         sx={{
    //           minHeight: '800px',
    //         }}
    //       >
    //         <Grid
    //           container
    //           item
    //           xs={3}
    //           alignItems="center"
    //           justifyContent="center"
    //           sx={{
    //             minHeight: '800px',
    //           }}
    //         >
    //           <Typography
    //             align="left"
    //             variant="h2"
    //             sx={{
    //               writingMode: 'tb-rl',
    //               transform: 'rotate(-180deg)',
    //               letterSpacing: '2px',
    //               color: colorGold,
    //               p: 4,
    //             }}
    //           >
    //             About us
    //           </Typography>
    //         </Grid>
    //         <Grid
    //           item
    //           container
    //           justifyItems="center"
    //           alignItems="center"
    //           xs={9}
    //           sx={{
    //             pl: 8,
    //             pr: 8,
    //             backgroundColor: grey[200],
    //             minHeight: '800px',
    //           }}
    //         >
    //           <Typography variant="body1" component="span">
    //             <NewLineText text={aboutText} />
    //           </Typography>
    //         </Grid>
    //       </Grid>
    //     )}
    //   </Box>
    // </BackgroundImage>
  );
};

export default SectionAbout;
